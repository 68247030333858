<template>
  <div>
     <div class="donghua" ref="animationContainer"> </div>

    
  </div>
</template>
 
<script>
// 引入组件
import lottie from "lottie-web"
 
// 引入动画
import animationData from "../assets/animation_ll21uhh9.json";
export default {
  name: 'Test',
  data() {
    return {
      animation:null
    }
  },
  mounted(){
    const container = this.$refs.animationContainer
    const options = {
      animationData: animationData,
      loop: true,
      autoplay: true
    }
    this.animation = lottie.loadAnimation({
      container: container,
      renderer: 'svg',
      ...options
    })
  },
  methods: {
   
  }
}
</script>
<style type="text/css">
  .donghua{
    width: 200px;
    
  }
</style>
