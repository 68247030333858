import Vue from 'vue'
import Vuex from 'vuex'
import storage from '@/utils/storage'

Vue.use(Vuex);
// 用Vuex.Store对象用来记录token
const store = new Vuex.Store({

  state: {
    // 存储token
    token: "",
    userName: "", // 可选
    
  },

  getters: {
    getToken(state) {
      return state.token || storage.get("token") || "";
    },
    getUserName(state) {
      return state.userName || storage.get("userName") || "";
    }
  },

  mutations: {
    // 修改token，并将token存入localStorage
    setToken(state, token) {
      state.token = token;
      storage.set('token', token);
      console.log('store、localstorage保存token成功！');
    },
    delToken(state) {
      state.token = "";
      storage.remove("token");
      state.userName = "";
      storage.remove("userName");
    },
    // 可选
    setUserInfo(state, userInfo) {
      storage.set('userInfo', userInfo);
      state.userInfo = userInfo;
    }
  },

  actions: {
    // removeToken: (context) => {
    // context.commit('setToken')
    // }
  },
});

export default store;
