import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/login',
        component:() => import('../views/Login.vue'),
        meta:{
            title:'登录'
        }
    },
    {
        path: '/',
        component:() => import('../views/Index.vue'),
        meta:{
            title:'魏晓莱',
            auth: true
        }
    },
    {
        path: '/test',
        component:() => import('../views/Test.vue'),
        meta:{
            title:'测试',
            auth: true
        }
    }
]


const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})

// 路由守卫
router.beforeEach((to,from,next)=>{
    // to要跳转到的路径
    // from从哪个路径来
    // next往下执行的回调
    // 在localStorage中获取token
    let token=localStorage.getItem('token')
    window.document.title = to.meta.title
    // 判断该页面是否需要登录
    if(to.meta.auth){
        // 如果token存在直接跳转
        if(token){
            next()
        }else{
            // 否则跳转到login登录页面
            next({
                path:'/login',
                // 跳转时传递参数到登录页面，以便登录后可以跳转到对应页面
                query:{
                    redirect:to.fullPath
                }
            })
        }
    }else{
        // 如果不需要登录，则直接跳转到对应页面
        next()
    }
})
export default router