import request from '@/utils/request'


// 获取会话列表
export function getChatList(query) {
  return request({
    url: '/get_chat_list',
    method: 'get',
    params: query
  })
}

// 获取消息列表
export function getMessageList(query) {
  return request({
    url: '/get_message_list',
    method: 'get',
    params: query
  })
}

// 删除会话
export function deleteChatList(data) {
  return request({
    url: '/delete_chat_list',
    method: 'post',
    data: data
  })
}

// 登录
export function loginDo(data) {
  return request({
    url: '/login_do',
    method: 'post',
    data: data
  })
}

// 保存消息
export function saveMessage(data) {
  return request({
    url: '/save_message',
    method: 'post',
    data: data
  })
}










