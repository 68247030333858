import axios from 'axios'
import { MessageBox, Message } from 'element-ui'
import store from '@/store'
import storage from '@/utils/storage'
import router from '../router'
axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: process.env.VUE_APP_BASE_API,
  // 超时
  timeout: 100000
})
// request拦截器
service.interceptors.request.use(config => {
  // 是否需要设置 token
  const isToken = (config.headers || {}).isToken === false
  let token = store.getters.getToken
  if (token && !isToken) {
    token = token !== "undefined" && token !== "" ? token : ''
  }
  
  if (config.method == 'post' || config.method == 'delete') {
      let data = config.data
      config.data = {
        token: token,
        ...data
      }
    } else if (config.method == 'get') {
      config.params = {
        token: token,
        ...config.params
      }
    }
  return config
}, error => {
    console.log(error)
    Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use(res => {
    
    // 未设置状态码则默认成功状态
    const code = res.data.code || 200;
    if (code === 40002) {
      storage.remove("token")
      MessageBox.confirm('登录状态已过期，请重新登录', '系统提示', {
          confirmButtonText: '重新登录',
          confirmButtonClass:'confirmButtonClass',
          showCancelButton:false,
          showClose:false,
          distinguishCancelAndClose:true,
          closeOnClickModal:false,
          closeOnPressEscape:false,
          type: ''
        }
      ).then(() => {
        router.replace({ path: "/login" });
      })
    } else {
      return res.data
    }
  },
  error => {
    let { message } = error;
    if (message === "Network Error") {
      message = "后端接口连接异常";
    }
    else if (message.includes("timeout")) {
      message = "系统接口请求超时";
    }
    else if (message.includes("Request failed with status code")) {
      message = "系统接口" + message.substr(message.length - 3) + "异常";
    }
    Message({
      message: message,
      type: 'error'
    })
    return Promise.reject(error)
  }
)
export default service
