<template>
  <div>
    <div class="donghua-box">
      <div class="donghua" ref="animationContainer"> </div>
    </div>
    
    <el-container style="height: 97vh; border: 1px solid #eee">
      <el-aside width="250px" v-if="screenWidth >=770">
        <el-scrollbar ref="myScrollbar" style="height: 96vh;">
          <div class="left-button-top">
            <el-button  style="width: 100%;" @click="addNewChat('新会话')">新会话</el-button>
          </div>
          <div class="left-button" v-for="(chat,index) in chatList" :key="chat.uuid">
            <el-menu @select="menuSelect" :default-active="menuIndex">
              <el-menu-item :index="''+index">
                <div style="display: flex; border-bottom: 1px solid rgb(238, 238, 238);">
                  <div class="left-title">{{chat.title}}</div>
                  <div class="left-act" v-if="menuIndex == index">
                    <el-popover
                      placement="top"
                      width="160"
                      trigger="hover"
                      v-model="visible">
                      <p>确定删除吗？</p>
                      <div style="text-align: right; margin: 0">
                        <el-button size="mini" type="text" @click="visible = false">取消</el-button>
                        <el-button type="primary" size="mini" @click="delSure(index)">确定</el-button>
                      </div>
                      <i slot="reference" class="el-icon-delete"></i>
                    </el-popover>
                  </div>
                </div>
              </el-menu-item>
            </el-menu>
          </div>
        </el-scrollbar>
      </el-aside>
      <el-container>
        <div style="height: 5vh; border: 1px solid rgb(238, 238, 238);display: flex;align-items: center;font-size: 27px;color: #409EFF;cursor: pointer;justify-content: space-between;">
          <i class="el-icon-menu menu-icon" @click="chat_uuid = ''" ></i>
          <i class="el-icon-s-fold menu-icon" v-if="screenWidth <= 770" @click="showDrawer = true" ></i>
        </div>
        <el-scrollbar style="height: 85vh;background: #fff; border: 1px solid rgb(238, 238, 238);" ref="mainScrollbar">
          <el-main style="padding-left: 0px;padding-right: 0px;">
            <div class="chat-list" v-loading="messageLoading" v-if="chat_uuid != ''">
              <div v-for="(msg,index) in messageList" :key="'msg_'+index">
                  
                  <div class="message sent" v-if="msg.role == 'me'">
                    <div class="message-content">
                      <div class="sender-info">
                        <span class="timestamp">{{msg.time}}</span>
                      </div>
                      <p class="message-text">
                        <v-md-preview :text="msg.content"></v-md-preview>
                      </p>
                    </div>
                    <div class="avatar">
                      <img :src="userImg" alt="Sender Avatar" />
                    </div>
                  </div>
                  <div class="message received" v-else>
                    <div class="avatar">
                      <img :src="backImg" alt="Receiver Avatar" />
                    </div>
                    <div class="message-content">
                      <div class="sender-info">
                        <span class="timestamp">{{msg.time}}</span>
                      </div>
                      <p class="message-text">
                        <v-md-preview :text="msg.content"></v-md-preview>
                      </p>
                    </div>
                  </div>
              </div>

              <div class="stopButton" v-if="showStopButton">
                <el-button type="warning" @click="stopOut">停止输出</el-button>
              </div>
              
            </div>
            <div v-else>
              <el-row>
                <el-col v-for="(o,i) in suggests" :span="screenWidth >=770?5:24" :offset="1" :key="o" style="cursor: pointer;margin-top: 20px;">
                  <el-card class="box-card" >
                    <div class="card-text" @click="suggestsComfirm(o)">
                      {{ o }}
                    </div>
                  </el-card>
                </el-col>
              </el-row>
            </div>
          </el-main>
        </el-scrollbar>
        <el-footer style="padding: 0;">
          <el-row style="height: 10vh;bottom: 2vh;display: flex;align-items: center;margin-top:4px;">
            <el-col :span="18">
              <el-input type="textarea" v-model="sendText"  @keyup.enter.native="pressEnter" resize="none" :placeholder="screenWidth >=770? '向我提问...(Shift + Enter = 换行)':'向我提问...'" :rows="2" ></el-input>
            </el-col>
            <el-col v-if="screenWidth > 770" :span="2" :offset="1">
              <el-tooltip content="开启上文关联" placement="top">
                <el-switch
                  v-model="withLast"
                  active-color="#13ce66"
                  inactive-color="#ff4949">
                </el-switch>
              </el-tooltip>
            </el-col>
            <el-col :span="2" :offset="screenWidth > 770?0:1">
              <el-button type="primary" :loading="buttonLoading" @click="sendMsg" icon="el-icon-s-promotion" :size="size"></el-button>
            </el-col>
          </el-row>
        </el-footer>
      </el-container>

      <el-drawer
        title="消息列表"
        direction="ltr"
        :size="screenWidth> 770?'30%':'70%'"
        :visible.sync="showDrawer">
          <el-scrollbar ref="myScrollbar" style="height: 96vh;">
            <div class="left-button-top">
              <el-button  style="width: 100%;" @click="addNewChat('新会话')">新会话</el-button>
            </div>
            <div class="left-button" v-for="(chat,index) in chatList" :key="chat.uuid">
              <el-menu @select="menuSelect" :default-active="menuIndex">
                <el-menu-item :index="''+index">
                  <div style="display: flex; border-bottom: 1px solid rgb(238, 238, 238);">
                    <div class="left-title">{{chat.title}}</div>
                    <div class="left-act" v-if="menuIndex == index">
                      <el-popover
                        placement="top"
                        width="160"
                        trigger="hover"
                        v-model="visible">
                        <p>确定删除吗？</p>
                        <div style="text-align: right; margin: 0">
                          <el-button size="mini" type="text" @click="visible = false">取消</el-button>
                          <el-button type="primary" size="mini" @click="delSure(index)">确定</el-button>
                        </div>
                        <i slot="reference" class="el-icon-delete"></i>
                      </el-popover>
                    </div>
                  </div>
                </el-menu-item>
              </el-menu>
            </div>
          </el-scrollbar>
        
      </el-drawer>
    </el-container>
  </div>
</template>

<script type="text/javascript">
  import backImg from "../assets/AI.png";
  import userImg from "../assets/userImg.jpg";
  import { getChatList,getMessageList,deleteChatList,saveMessage } from "@/api/index";
  // 引入组件
  import lottie from "lottie-web"
  // 引入动画
  import animationData from "../assets/animation_ll21uhh9.json";
  export default {
    name: 'index',
    data() {
      let self = this
      return {
        backImg:backImg,
        userImg:userImg,
        size:'large',
        tabPosition: 'left',
        screenWidth:770,
        markdownText:'',
        renderedMarkdown: '',
        eventSource:null,
        buttonLoading:false,
        sendText:'',
        visible:false,
        menuIndex:'',
        chat_uuid:'',
        chatList:[],
        messageList:[],
        messageLoading:false,
        withLast:true,
        showDrawer:false,
        suggests:[
          '如何优化卫星的载荷部署？',
          '哪个季节对火箭发射窗口来说是最理想的？',
          '航天器的重返地球过程中需要采取哪些措施来确保安全？',
          '如何在卫星运行期间进行轨道调整？',
          '在航天项目中如何评估和提高系统的可靠性？',
          '航天器如何避免与其他航天器的碰撞？',
          '怎样才能在航天任务中实现成本效益最大化？'
        ],
        showStopButton:false,
        animation:null,
        prompt:''

      }
    },
    mounted() {
      let that = this
      that.handleResize(); // 初始化屏幕宽度
      window.addEventListener('resize', that.handleResize);
      
      that.gchatList()

      const container = that.$refs.animationContainer
      const options = {
        animationData: animationData,
        loop: true,
        autoplay: true
      }
      that.animation = lottie.loadAnimation({
        container: container,
        renderer: 'svg',
        ...options
      })
      
    },
    beforeUnmount() {
      window.removeEventListener('resize', this.handleResize);
    },
    created(){
      if(this.prompt){
        this.addNewChat(this.prompt)
        this.sendText = this.prompt
        this.sendMsg()


      }
    },

    watch: {
      $route: {
        handler: function(route) {
          this.prompt = route.query && route.query.prompt
        },
        immediate: true
      }
    },
    
    methods: {
      suggestsComfirm(e){
        this.addNewChat(e)
        this.sendText = e
        this.sendMsg()
      },
      gchatList(){
        let that = this
        getChatList({}).then(
          response => {
            that.chatList = response.data
            if (that.prompt && that.prompt != '' ) {
              that.chatList.unshift({uuid:that.chat_uuid,title:that.prompt,create_at:that.getNowDate()});
              that.menuIndex = '0'
              that.$forceUpdate()

              // 获取当前路由对象
              const currentRoute = that.$router.currentRoute;

              // 获取当前路由的路径，不包含查询参数
              const routePath = currentRoute.path;

              // 使用 $router.replace 方法更新路由，但不进行页面跳转
              that.$router.replace({ path: routePath });
            }
          }
        );
      },
      addNewChat(title){
        let uuid = this.dealChatUuid()
        this.chatList.unshift({uuid:this.chat_uuid,title:title,create_at:this.getNowDate()});
        this.messageList = []
        this.menuIndex = '0'
        this.$forceUpdate()
      },
      delSure(ind){
        let that = this
        let chat_uuid = that.chatList[ind].uuid
        let list_length = that.chatList.length
        deleteChatList({chat_uuid}).then(
          response => {
            that.visible = false
            that.gchatList()
            if (list_length - 1 > 0) {
              that.menuSelect('0')
            }
          }
        );
      },
      dealChatUuid(uuid){
        if (!uuid) { 
          this.chat_uuid = Date.now();
        }else{
          this.chat_uuid = uuid;
        }
      },
      menuSelect(e){
        let that = this
        that.menuIndex = e
        that.dealChatUuid(that.chatList[e].uuid)
        that.messageLoading = true
        getMessageList({chat_uuid:that.chatList[e].uuid}).then(
          response => {
            that.messageLoading = false
            that.messageList = response.data
            that.$nextTick(() => {
              that.scrollBottom();
              that.showDrawer = false
            })
            
          }
        );
      },
      scrollBottom(){
        this.$refs['mainScrollbar'].wrap.scrollTop = this.$refs['mainScrollbar'].wrap.scrollHeight;
      },
      getNowDate(){
        var currentDate = new Date();
        var year = currentDate.getFullYear();
        var month = ('0' + (currentDate.getMonth() + 1)).slice(-2);
        var day = ('0' + currentDate.getDate()).slice(-2);
        var hours = ('0' + currentDate.getHours()).slice(-2);
        var minutes = ('0' + currentDate.getMinutes()).slice(-2);
        var seconds = ('0' + currentDate.getSeconds()).slice(-2);

        var formattedDate = year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;
        return formattedDate;
      },
      sendMsg(){
        let that = this
        if (that.sendText.length == 0) {
          return
        }
        if (that.chat_uuid == '') {
          that.dealChatUuid()
          that.chatList.unshift({uuid:that.chat_uuid,title:that.sendText,create_at:that.getNowDate()});
        }
        that.messageList.push({role:'me',content:that.sendText,time:that.getNowDate()});
        that.$nextTick(function(){
          that.scrollBottom();
        })
        
        that.messageList.push({role:'ai',content:'',time:that.getNowDate()});
        let currind = that.messageList.length
        that.buttonLoading = true
        that.$nextTick(function(){
          that.scrollBottom();
        })
        let token = that.$store.getters.getToken
        let sendMessage = JSON.parse(JSON.stringify(that.sendText))
        that.eventSource = new EventSource(process.env.VUE_APP_BASE_API+`/send_msg?chat_uuid=${that.chat_uuid}&message=${encodeURIComponent(that.sendText)}&withLast=${that.withLast}&token=${token}`);
        that.sendText = ''
        that.eventSource.onmessage = function (e) {
          var data = e.data;
          data = data.replace(/<br\s*\/?>/g, "\n")
          data = data.replaceAll("&nbsp;", " ")
          let da_arr = JSON.parse(data);
          if(da_arr.is_end)
          {
            that.buttonLoading = false
            that.showStopButton = false
            let response = that.messageList[currind-1].content
            that.saveMessage(that.chat_uuid,sendMessage,response);

          }else{
            that.showStopButton = true
            that.messageList[currind-1].content += da_arr.result;
            that.scrollBottom()
          }
          
       };
       that.eventSource.onerror = function (e) {
          that.buttonLoading = false
          that.eventSource.close()
       };
      },
      saveMessage(chat_uuid,message,response){

        saveMessage({chat_uuid,message,response}).then(
          response => {
            
          }
        );
      },
      stopOut(){
        this.buttonLoading = false
        this.showStopButton = false
        this.eventSource.close()
      },
      handleResize() {
        this.screenWidth = window.innerWidth;
      },
      pressEnter(e){
        if (!e.shiftKey) {
          this.sendMsg()
        }
        return false;
      }
    }
  }
</script>
<style>
.menu-icon{
  margin-left: 10px;
  margin-right: 10px;
}
.github-markdown-body {
  padding: 10px !important;
}
.left-button{
  padding: 5px;
}
.left-button-top{
  padding: 10px 5px 10px 5px;
}

.el-textarea {
  resize: none;
}

/* 在屏幕宽度小于等于768像素时应用的样式 */
@media (max-width: 768px) {
  .ant-layout-sider {
    display: none;
  }
  .layout-main-box{
    margin-left: 10px;
  }
  .ant-layout-footer{
    padding: 12px 10px
  }
}

/* 在屏幕宽度大于768像素时应用的样式 */
@media (min-width: 769px) {
  .layout-main-box{
    margin-left: 250px;
  }
}

.el-scrollbar__wrap {
    overflow-x: hidden;
  }

body > .el-container {
  margin-bottom: 40px;
}

.el-container:nth-child(5) .el-aside,
.el-container:nth-child(6) .el-aside {
  line-height: 260px;
}

.el-container:nth-child(7) .el-aside {
  line-height: 320px;
}

.chat-list {
  /*height: 80vh;*/
  /*overflow: auto;*/
}

.message {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
}

.sent .message-content {
  background-color: #e0eaf3;
  border-radius: 8px 8px 0 8px;
  margin-left: auto;
}

.received .message-content {
  background-color: #f3f3f3;
  border-radius: 8px 8px 8px 0;
  margin-right: auto;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 10px;
  margin-left: 10px;
}

.avatar img {
  max-width: 100%;
}

.message-content {
  max-width: 70%;
  padding: 5px;
}

.sender-info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.timestamp {
  color: gray;
}

.message-text {
  margin: 0;
  width: 100%;
}

.markdown-container {
  margin-top: 1em;
  padding: 1em;
  border: 1px solid #ccc;
}

.left-title{
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 60%;
  flex: 1;
}
.card-text{
  display: -webkit-box;
  -webkit-line-clamp: 2; /* 控制显示的行数 */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.stopButton{
  width: 60%;
  text-align: center;
  position: fixed;
  bottom: 12vh;
}

@media (max-width: 768px) {
  .stopButton{
    width: 85%;
  }
}

@media (min-width: 769px) {
  .stopButton{
    width: 60%;
  }
}

@media (max-width: 768px) {
  .donghua-box{
    display: none;
  }
}

.donghua-box{
  width: 100%;
  text-align: right;
}
.donghua{
  width: 200px; 
  position: fixed;
  bottom: 12vh;
  right: 20px;
  z-index: 500;
}
.el-scrollbar__wrap{
  margin-right: 17px;
}

</style>
