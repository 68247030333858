<template>
  <div v-loading="loading" element-loading-text="登录中..." element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.6)" class="login-container">
 
    <!-- <el-form ref="loginForm" :model="loginForm" :rules="loginRules" class="login-form" auto-complete="on" label-position="left">
      <div v-if="TxStatus" class="avatar-box">
        <img src="../assets/userImg.jpg" alt="">
      </div>
 
      <div class="title-container">
        <h3 class="title">智能客服系统</h3>
      </div>
 
      <el-form-item prop="username">
        <span class="svg-container">
        	<i class="el-icon-user"></i>
        </span>
        <el-input ref="username" v-model="loginForm.username" placeholder="Username" name="username" type="text" tabindex="1" auto-complete="on" />
      </el-form-item>
 
      <el-form-item prop="password">
        <span class="svg-container">
          <i class="el-icon-lock"></i>
        </span>
        <el-input :key="passwordType" ref="password" v-model="loginForm.password" :type="passwordType" placeholder="Password" name="password" tabindex="2" auto-complete="on" @keyup.enter.native="handleLogin" />
      </el-form-item>
      <div>
        <el-button type="primary" style="width:100%;margin-bottom:20px;" @click.native.prevent="handleLogin">登录</el-button>
      </div>
    </el-form> -->
  </div>
</template>
 
<script>
// 引入去除空格工具
import { validUsername } from '@/utils/validate'
import { loginDo } from "@/api/index";
export default {
  name: 'Login',
  data() {
    const validateUsername = (rule, value, callback) => {
      if (value.length  == 0) {
        callback(new Error('用户名不能为空！'))
      } else {
        callback()
      }
    }
    const validatePassword = (rule, value, callback) => {
      if (value.length < 6) {
        callback(new Error('密码最少为6位字符！'))
      } else {
        callback()
      }
    }
    return {
      // 头像状态
      TxStatus: true,
      loginForm: {
        username: '',
        password: ''
      },
      // 登录规则
      loginRules: {
        username: [{ required: true, trigger: 'blur', validator: validateUsername }],
        password: [{ required: true, trigger: 'blur', validator: validatePassword }]
      },
      loading: false,
      passwordType: 'password',
      redirect: undefined,
      openid:'',
      name:'',
      avatar:''
    }
  },
  watch: {
    $route: {
      handler: function(route) {
        this.openid = route.query && route.query.openid
        this.name = route.query && route.query.name
        this.avatar = route.query && route.query.avatar
      },
      immediate: true
    }
  },
  created(){

    // let info = {
    //   avatar: "https://s1-imfile.feishucdn.com/static-resource/v1/v2_a98ae392-1eab-47ab-b223-b5b7907bf5eg~?image_size=240x240",
    //   name: "拓强强",
    //   openid: "ou_e550886623c29bc3c65535c3b7c3c937"
    // }

    // this.$store.commit("setToken",info.openid)
    //   this.$store.commit("setUserInfo",info)
    //   return
    if(this.openid && this.avatar && this.name){
      let userinfo = {
        openid:this.openid,
        name:this.name,
        avatar:this.avatar
      }
      this.$store.commit("setToken",userinfo.openid)
      this.$store.commit("setUserInfo",userinfo)
      setTimeout(() => {
       this.$router.push({ path: '/' })
      }, 400)
    }else{
      let token = this.$store.getters.getToken
      if (token) {
        this.$router.push({ path: '/' })
      }else{
        location.replace("https://passport.feishu.cn/suite/passport/oauth/authorize?client_id=cli_a4c254b716ba500c&redirect_uri=https://chat.spacef.com/api/feishu_get_token&response_type=code&state=state123456")
      }
    }
  },
  methods: {
    // 登录业务
    handleLogin() {
      this.$refs.loginForm.validate((valid) => {
        // 如果符合验证规则
        if (valid) {
          	this.loading = true
          	loginDo(this.loginForm).then(
	          response => {
	          	this.loading = false
	            if(response.code == 200){
	            	this.msgSuccess("登录成功！");
		            this.$store.commit("setToken",response.data.token)
		            this.$store.commit("setUserInfo",response.data.username)
	            	this.$router.push({ path: '/' })
	            }else{
	            	this.msgError(response.msg)
	            }
	          }
	        );
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  }
}
</script>
 
<style>

@supports (-webkit-mask: none) and (not (cater-color: #fff)) {
  .login-container .el-input input {
    color: #fff;
  }
}

.login-container .el-input {
  display: inline-block;
  height: 47px;
  width: 87%;
}

.login-container .el-input input {
  background: transparent;
  border: 0px;
  -webkit-appearance: none;
  border-radius: 0px;
  padding: 12px 5px 12px 15px;
  color: #fff;
  height: 47px;
  caret-color: #fff;
}

.login-container .el-input input:-webkit-autofill {
  box-shadow: 0 0 0px 1000px #283443 inset !important;
  -webkit-text-fill-color: #fff !important;
}

.login-container .el-form-item {
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  color: #454545;
}


</style>
 
<style>
 
.login-container {
  min-height: 100%;
  width: 100%;
  overflow: hidden;
  background: url(~@/assets/bg0.jpg);
  background-size: 100% 100%;
}

.login-container .avatar-box {
  margin: 0 auto;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: 1px solid #409eff;
  box-shadow: 0 0 10px #409eff;
  position: relative;
  bottom: 20px;
}

.login-container .avatar-box img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.login-container .login-form {
  position: relative;
  /*width: 520px;*/
  max-width: 520px;
  padding: 160px 35px 0;
  margin: 0 auto;
  overflow: hidden;
  height: 78vh;
}

.login-container .tips {
  font-size: 18px;
  text-align: center;
  color: #000;
  margin-bottom: 10px;
}

.login-container .svg-container {
  padding: 6px 5px 6px 15px;
  color: #889aa4;
  vertical-align: middle;
  width: 7%;
  display: inline-block;
}

.login-container .title-container {
  position: relative;
}

.login-container .title-container .title {
  font-size: 30px;
  color: #fff;
  margin: 0px auto 40px auto;
  text-align: center;
  font-weight: 500;
}

.login-container .show-pwd {
  position: absolute;
  right: 10px;
  top: 7px;
  font-size: 16px;
  color: #fff;
  cursor: pointer;
  user-select: none;
}

</style>